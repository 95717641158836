<template>
  <div class="container">
    <h4 class="text-center my-4">Список заявок на вакансии</h4>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3">

      <DataTable :value="vacancyApplications" showGridlines stripedRows
                 :globalFilterFields="['lastname','firstname']"
                 v-model:filters="filters"
                 :paginator="true"
                 :rows="10"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 responsiveLayout="scroll"
                 currentPageReportTemplate="Показано с {first} по {last} из {totalRecords}">
        <template #header>
          <div class="p-d-flex p-jc-between p-ai-center">
            <span class="p-input-icon-left">
              <i class="pi pi-search"/>
              <InputText v-model="filters['global'].value" placeholder="Поиск кандидата"/>
            </span>
          </div>
        </template>
        <template #empty>
          Кандидат не найден
        </template>
        <template #loading>
          Идет поиск кандидатов. Пожалуйста подождите.
        </template>

        <Column header="№" :style="{width: '60px'}">
          <template #body="{index}">
            {{index+1}}
          </template>
        </Column>

        <Column header="ФИО">
          <template #body="{data}">
            <a @click.prevent="goToUser(data.id)" class="text-decoration-none" href="#">
              {{data.lastname}} {{data.firstname}}
            </a>
          </template>
        </Column>

        <Column header="Дисциплины">
          <template #body="{data}">

            <div v-for="(application, index) in data.applications" :key="index" class="my-4">
              <div class="fw-bold">
                {{application.education_discipline_name}}
              </div>
              <div>
                <span class="me-2">{{application.education_discipline_code}}</span>
                <span class="me-2">(Кредит {{application.education_discipline_credit}})</span>
                {{application.study_level_name}} - {{application.language}}
              </div>
            </div>
          </template>
        </Column>

        <Column header="Дата проведения" class="text-center" :style="{width: '20%'}">
          <template #body="{data}">
            <div v-if="data.interviewTimeVisible" class="input-group">
              <input v-model="data.interviewTime" :disabled="data.isInterviewTimeId&&!data.editStatus" type="date"
                     class="form-control">
              <Button v-if="!data.isInterviewTimeId" icon="pi pi-save" :disabled="loadingInterviewTime"
                      @click="saveInterviewTime(data.id)"/>
              <Button v-else-if="!data.editStatus" icon="pi pi-pencil" class="p-button-warning"
                      @click="data.editStatus=true"/>
              <Button v-else icon="pi pi-check" :disabled="loadingInterviewTime"
                      @click="saveInterviewTime(data.id)"/>
            </div>

          </template>
        </Column>
      </DataTable>

    </div>


  </div>
</template>

<script>
  import {mapActions, mapMutations} from 'vuex'
  import {FilterMatchMode, FilterOperator} from 'primevue/api'
  import {convertDateInputToTimestamp, convertTimestampToDate} from "@/utils/helpers/date.helpers"

  export default {
    name: 'HRHeadVacancyApplications',
    data() {
      return {
        loading: true,
        filters: {
          'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
          'lastname': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
          },
          'firstname': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
          },
        },
        vacancyApplications: [],
        loadingInterviewTime: false,
      }
    },
    computed: {},
    methods: {
      ...mapActions('kkk', ['GET_VACANCY_APPLICATIONS',
        'POST_INTERVIEW_TIME', 'PUT_INTERVIEW_TIME'
      ]),
      ...mapMutations('userinfo', ['SET_USER_ID']),
      convertDateInputToTimestamp,
      convertTimestampToDate,
      goToUser(userId) {
        this.SET_USER_ID(userId)
        this.$router.push('/user')
      },
      async getVacancyApplications() {

        const vacancyApplications = await this.GET_VACANCY_APPLICATIONS()

        vacancyApplications.forEach(i => {
          i.interviewTimeVisible = !!i.applications.length
          if (i.interviewTimeVisible) {
            i.isInterviewTimeId = !!i.applications[0].kkk_interview_time_id
            i.interviewTime = i.applications[0].kkk_interview_time_date ? this.convertTimestampToDate(i.applications[0].kkk_interview_time_date) : null
            i.editStatus = false
          }

        })

        this.vacancyApplications = vacancyApplications
      },
      async saveInterviewTime(userId) {
        this.loadingInterviewTime = true

        const user = this.vacancyApplications.find(i => i.id === userId)

        if (!user.interviewTime) {
          return this.$error({title: 'Выберите время'})
        }

        let res = false

        const postData = user.applications.filter(i => !i.kkk_interview_time_id).map(i => ({
          user_syllabus_id: i.user_syllabus_id,
          date: this.convertDateInputToTimestamp(user.interviewTime),
        }))

        if (postData.length) {
          res = await this.POST_INTERVIEW_TIME(postData)
        }


        const putData = user.applications.filter(i => !!i.kkk_interview_time_id).map(i => ({
          id: i.kkk_interview_time_id,
          user_syllabus_id: i.user_syllabus_id,
          date: this.convertDateInputToTimestamp(user.interviewTime),
        }))

        if (putData.length) {
          res = await this.PUT_INTERVIEW_TIME(putData)
        }

        if (res) {
          await this.getVacancyApplications()
          this.$message({title: 'Сохранено'})
        }

        this.loadingInterviewTime = false
      },
    },
    async mounted() {
      await this.getVacancyApplications()
      this.loading = false
    },
  }

</script>